// dependencies
import React, { useCallback, useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

// contexts
import { useSelectContext } from "./context";

// constants
import theming from "@cloudspire/legacy-resources/src/constants/theming";

const { useTheme } = theming;

const styles = StyleSheet.create({
  option: {
    fontSize: "0.75rem",
    backgroundColor: "#ffffff",
    paddingTop: "0.625rem",
    paddingRight: "0.625rem",
    paddingBottom: "0.625rem",
    paddingLeft: "0.625rem",
    borderColor: "#cfdadd",
    borderBottomWidth: "0.0625rem",
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    cursor: "default",
  },
  option__hovered: {
    backgroundColor: "#f9eaea",
  },
  option__disabled: {
    backgroundColor: "#f3ecec",
    color: "#808080",
  },
});

type PropsType = {
  value: string | number;
  disabled?: boolean;
  depth?: number;
  checked?: boolean;
  hovered?: boolean;
};

const isChecked = ({ multiple, optionValue, contextValue }) => {
  return multiple
    ? contextValue.includes(optionValue)
    : contextValue === optionValue;
};

const SelectOption: React.FunctionComponent<PropsType> = (props) => {
  const { value, disabled, depth, children } = props;

  const {
    value: contextValue,
    hover,
    native,
    disabled: contextDisabled,
    multiple,
    selectId,
    onChangeValue,
    onHover,
  } = useSelectContext();

  const theme = useTheme();

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      option: {
        fontFamily: theme.FONT_FAMILY,
      },
    });
  }, [theme]);

  const handleClick = useCallback(() => {
    if (!(disabled || contextDisabled)) {
      onChangeValue(value);
    }
  }, [value, disabled, contextDisabled, onChangeValue]);

  const handleMouseOver = useCallback(() => {
    if (!(disabled || contextDisabled)) {
      onHover(value);
    }
  }, [disabled, contextDisabled, value, onHover]);

  const checked = isChecked({ contextValue, optionValue: value, multiple });

  const hovered = value === hover;

  const optionId = `${selectId}-${value}`;

  return (
    <>
      {native ? (
        <option value={value} disabled={disabled || contextDisabled}>
          {children}
        </option>
      ) : (
        <div
          role="option"
          id={optionId}
          aria-disabled={disabled || contextDisabled}
          data-value={value}
          aria-selected={checked}
          className={css(
            styles.option,
            dynamicStyles.option,
            hovered && styles.option__hovered,
            (disabled || contextDisabled) && styles.option__disabled
          )}
          style={{
            paddingLeft: `${depth * 0.625 + 0.625}rem`,
          }}
          onMouseOver={handleMouseOver}
          onClick={handleClick}
        >
          {checked && "✓ "}
          {children}
        </div>
      )}
    </>
  );
};

export default SelectOption;
