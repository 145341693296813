// dependencies
import React, { useState } from "react";
import { usePopper } from "react-popper";

// components
import Portal from "@cloudspire/legacy-resources/src/components/Portal";

// constants
import {
  sameWidth,
  applyMaxSize,
  maxSize,
} from "@cloudspire/legacy-resources/src/constants/popperjs";

type PropsType = {
  reference: HTMLElement;
  container: React.ReactNode;
  popover: React.ReactNode;
  visible: boolean;
  gutter?: number;
  useSameWidth?: boolean;
  usePreventOverlow?: boolean;
  useApplyMaxSize?: boolean;
};

const Popover3: React.FunctionComponent<PropsType> = (props) => {
  const {
    reference,
    gutter = 10,
    visible,
    container,
    popover,
    useSameWidth = true,
    usePreventOverlow = false,
    useApplyMaxSize = false,
  } = props;

  const [popper, setPopper] = useState(null);

  const { styles, attributes } = usePopper(reference, popper, {
    strategy: "fixed",
    placement: "bottom-start",
    modifiers: [
      { name: "offset" as string, options: { offset: [0, gutter] } },
      ...(useSameWidth ? [sameWidth] : []),
      ...(useApplyMaxSize ? [maxSize, applyMaxSize] : []),
      ...(usePreventOverlow
        ? [
            {
              name: "preventOverflow",
              options: {
                altAxis: true,
              },
            },
          ]
        : []),
    ],
  });

  return (
    <>
      {container}

      <Portal selector="body">
        {visible && (
          <div
            ref={setPopper}
            style={{
              ...styles.popper,
              zIndex: 99,
              overflow: usePreventOverlow ? "auto" : undefined,
            }}
            {...attributes.popper}
          >
            {popover}
          </div>
        )}
      </Portal>
    </>
  );
};

Popover3.defaultProps = {
  gutter: 10,
  useSameWidth: true,
  usePreventOverlow: false,
  useApplyMaxSize: false,
};

export default Popover3;
