import { detectOverflow, Modifier, ModifierArguments } from "@popperjs/core";

export const sameWidth: Partial<Modifier<unknown, object>> = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.minWidth = `${
      (state.elements.reference as HTMLElement).offsetWidth
    }px`;

    return null;
  },
};

export const applyMaxSize: Partial<Modifier<unknown, object>> = {
  name: "applyMaxSize",
  enabled: true,
  phase: "beforeWrite",
  requires: ["maxSize"],
  fn({ state }) {
    const { height } = state.modifiersData.maxSize;
    state.styles.popper.maxHeight = `${height}px`;
  },
};

export const maxSize = {
  name: "maxSize",
  enabled: true,
  phase: "main",
  requiresIfExists: ["offset", "preventOverflow", "flip"],
  fn({ state, name, options }: ModifierArguments<{}>) {
    const overflow = detectOverflow(state, options);
    const { x, y } = state.modifiersData.preventOverflow || { x: 0, y: 0 };
    const { width, height } = state.rects.popper;
    const [basePlacement] = state.placement.split("-");

    const widthProp = basePlacement === "left" ? "left" : "right";
    const heightProp = basePlacement === "top" ? "top" : "bottom";

    state.modifiersData[name] = {
      width: width - overflow[widthProp] - x,
      height: height - overflow[heightProp] - y,
    };
  },
};
