// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";

// contexts
import { useSelectContext } from "./context";

const styles = StyleSheet.create({
  label: {
    fontSize: "0.75rem",
    fontWeight: 700,
    paddingTop: "0.625rem",
    paddingRight: "0.625rem",
    paddingBottom: "0.625rem",
    paddingLeft: "0.625rem",
    borderColor: "#cfdadd",
    borderBottomWidth: "0.0625rem",
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
  },
  list: {},
});

type PropsType = {
  label: string;
  depth?: number;
};

const SelectOptGroup: React.FunctionComponent<PropsType> = (props) => {
  const { label, children } = props;

  const { native } = useSelectContext();

  return (
    <>
      {native ? (
        <optgroup label={label}>{children}</optgroup>
      ) : (
        <div role="group">
          <div className={css(styles.label)}>{label}</div>

          <div role="listbox" className={css(styles.list)}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectOptGroup;
