// dependencies
import React, { useContext } from "react";

const selectContext = React.createContext<{
  selectId: string;
  value: string | number | Array<string>;
  hover: string | number;
  disabled: boolean;
  native: boolean;
  multiple: boolean;
  onChangeValue: (value: string | number | Array<string | number>) => void;
  onHover: (value: string | number | Array<string>) => void;
}>(null as any);

const Provider = selectContext.Provider;

export const SelectProvider = Provider;

export const useSelectContext = () => {
  return useContext(selectContext);
};
